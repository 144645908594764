import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { useSelector } from "react-redux";

export default function HeaderProfile() {


  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { name } = state;

  var welcomeFname;
  if (name) {
    welcomeFname = name.split(" ");
  } else {
    welcomeFname = "Unknown";
  }

  const logOut = async (e) => {
    e.preventDefault();
  
    userActions.logOut(null);

    navigate("/admin");
  };


  return (
    <header className="top-header">
      <nav className="navbar navbar-expand gap-3">
        <div className="mobile-toggle-icon fs-3">
          <i className="bi bi-list"></i>
        </div>
        <div className="top-navbar-right ms-auto">
          <ul className="navbar-nav align-items-center">
            <li className="nav-item">
              <Link className="nav-link" to="/admin/dashboard">
                <i class="bi bi-house-fill"></i> Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/profile-admin">
                <i class="bi bi-person-badge-fill"></i> Profile
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/admin/"
                onClick={(e) => {
                  logOut(e);
                }}
              >
                <i class="bi bi-box-arrow-right"></i> Logout
              </Link>
            </li>
            <li className="nav-item">
              <div className="red-welcome">
                <div className="trianles">
                  {" "}
                  <img
                    src="/admin_assets/images/lef-red.png"
                    className="lef-red"
                    alt=""
                  />{" "}
                  <img
                    src="/admin_assets/images/rig-red.png"
                    className="rig-red"
                    alt=""
                  />{" "}
                  <div className="welcome-txt">
                    Welcome - {welcomeFname[0] ? welcomeFname[0] : "Unknown"}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
