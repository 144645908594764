import * as Yup from "yup";

export const signInSchema = Yup.object({
  username: Yup.string().min(2).required("Please enter the Username"),
  password: Yup.string().min(2).required("Please enter the Password"),
  // name: Yup.string().min(2).max(10).required("Please enter the Name"),
  // confirm_password: Yup.string()
  // .required("Please enter the password again")
  // .oneOf([Yup.ref('password'),null],"Password must match")
});

export const forgotPassword = Yup.object({
  email: Yup.string().email().required("Please enter the Email"),
});

export const changePassword = Yup.object({
  password: Yup.string()
    .min(6, "Your password must be at least 6 characters long")
    .required("Please enter the Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      "Please enter atleast 1 uppercase,1 lowercase and 1 digit."
    ),
  confirm_password: Yup.string()
    .required("Please enter the password again")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

// FIXME image validation not working
export const submitUserSchema = Yup.object({
  product: Yup.number().required("Please select product you sold"),
  size: Yup.number().required("Please select product size"),
  invoice: Yup.string().min(2).required("Please enter delivered invoice"),
  invoice_date: Yup.string().required("Please select date"),
  quantity: Yup.number().required("Please select shipped quantity"),
  image: Yup.mixed().required("Please upload an image"),

  // FIXME all fields depend on product field, if product field is empty then what?
  product2: Yup.number(),
  size2: Yup.number().when("product2", {
    is: (product2) => product2,
    then: Yup.number().required("Please select product size"),
    otherwise: Yup.number(),
  }),
  invoice2: Yup.string()
    .min(2)
    .when("product2", {
      is: (product2) => product2,
      then: Yup.string().required("Please enter delivered invoice"),
      otherwise: Yup.string().min(2),
    }),
  invoice_date2: Yup.string()
    .min(2)
    .when("product2", {
      is: (product2) => product2,
      then: Yup.string().required("Please select date"),
      otherwise: Yup.string().min(2),
    }),
  quantity2: Yup.number().when("product2", {
    is: (product2) => product2,
    then: Yup.number().required("Please select shipped quantity"),
    otherwise: Yup.number(),
  }),
  image2: Yup.mixed().when("product2", {
    is: (product2) => product2,
    then: Yup.mixed().required("Please upload an image"),
    otherwise: Yup.mixed(),
  }),

  // FIXME all fields depend on product field, if product field is empty then what?
  product3: Yup.number(),
  size3: Yup.number().when("product3", {
    is: (product3) => product3,
    then: Yup.number().required("Please select product size"),
    otherwise: Yup.number(),
  }),
  invoice3: Yup.string()
    .min(2)
    .when("product3", {
      is: (product3) => product3,
      then: Yup.string().required("Please enter delivered invoice"),
      otherwise: Yup.string().min(2),
    }),
  invoice_date3: Yup.string()
    .min(2)
    .when("product3", {
      is: (product3) => product3,
      then: Yup.string().required("Please select date"),
      otherwise: Yup.string().min(2),
    }),
  quantity3: Yup.number().when("product3", {
    is: (product3) => product3,
    then: Yup.number().required("Please select shipped quantity"),
    otherwise: Yup.number(),
  }),
  image3: Yup.mixed().when("product3", {
    is: (product3) => product3,
    then: Yup.mixed().required("Please upload an image"),
    otherwise: Yup.mixed(),
  }),

  // split1: Yup.bool().oneOf([true], "You need to agree  the split1"),
  // split2: Yup.bool().oneOf([true], "You need to agree  the split2"),
  // split3: Yup.bool().oneOf([true], "You need to agree  the split3"),
});

export const quizSearch = Yup.object({
  fieldtype: Yup.number().required("Please Select a Column"),
  searchval: Yup.string().required("Please fill the search field"),
});

// admin schema here

export const UpAdminSchema = Yup.object({
  fname: Yup.string().min(2).required("Please enter the First Name"),
  lname: Yup.string().min(2).required("Please enter the Last Name"),
  zipcode: Yup.string().min(4).required("Please enter the Zip Code"),
  state: Yup.string().required("Please enter the State"),
  city: Yup.string().required("Please enter the City"),
  address: Yup.string().required("Please enter the City"),
  email: Yup.string().email().required("Please enter the Email"),
  // password: Yup.string()
  // .when({
  //   is: (password) => password,
  //   then: Yup.string()
  //     .required("Please enter the Password")
  //     .min(6, "Your password must be at least 6 characters long")
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
  //       "Please enter atleast 1 uppercase,1 lowercase and 1 digit."
  //     ),
  //   otherwise: Yup.string().notRequired(),
  // }),
  new_password: Yup.string().when({
    is: (new_password) => new_password,
    then: Yup.string()
      .required("Please enter the Password")
      .min(6, "Your password must be at least 6 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        "Please enter atleast 1 uppercase,1 lowercase and 1 digit."
      ),
    otherwise: Yup.string().notRequired(),
  }),

  confirm_password: Yup.string()
    .when("new_password", {
      is: (new_password) => new_password,
      then: Yup.string().required("Please enter the Password again"),
      otherwise: Yup.string().notRequired(),
    })
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});
