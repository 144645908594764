import React, { useEffect, useState } from "react";
import axios from "axios";
import FootRegister from "./FootRegister";
import HeadRegister from "./HeadRegister";
import { useFormik } from "formik";
import InputField from "../../InputField";
import { Link, useNavigate } from "react-router-dom";
import "./Registration.css";

import { signUpSchema } from "../../../schema/index";
import secureLocalStorage from "react-secure-storage";

import UserService from "../../../services/user.service";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import ToTop from "../Include/ToTop";
import { Helmet } from "react-helmet";
import DangerInfo from "../../AlertInfo/DangerInfo";
import Select from "react-select";

function Registration() {
  const TITLE = "Select Your Rebate | Register";

  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);

  const [userCityLoader, setUserCityLoader] = useState(false);
  const [image, setImage] = useState(null);

  const initialInputTypes = [
    { id: 1, type: "text" },
    { id: 2, type: "text" },
  ];
  const [inputTypes, setInputTypes] = useState(initialInputTypes);

  const handleInputClick = (id) => {
    const updatedInputTypes = inputTypes.map((input) => {
      if (input.id === id) {
        return { ...input, type: "date" };
      }
      return input;
    });
    setInputTypes(updatedInputTypes);
  };
  const navigate = useNavigate();
  //creating function to load ip address from the API
  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org?format=json");

    const weIp = res.data.ip;
    secureLocalStorage.setItem("ip", weIp);
  };

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await UserService.getUserState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setUserState([...results]);
    };

    getUserState();
  }, []);

  const changeUserCity = (e) => {
    setUserCity([]);

    const getUserCity = async () => {
      setUserCityLoader(true);
      try {
        const { data } = await UserService.getCityByStateId(e);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            label: value.city,
            value: value.id,
          });
        });
        setUserCity([...results]);
        setUserCityLoader(false);
      } catch (error) {
        setUserCity([0]);
        setUserCityLoader(false);
      }
    };

    if (e !== "") {
      getUserCity();
    }
  };

  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [emailerror, setEmailerror] = useState("");
  const [promotionError, setPromotionError] = useState("");
  const [promotionCodeError, setPromotionCodeError] = useState("");

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      promotion_id: "",
      promo_code: "",
      fname: "",
      lname: "",
      email: "",
      zipcode: "",
      stateprov2: "",
      city2: "",
      address1: "",
      phone: "",
      retailer: "",
      brand: "",
      model: "",
      purchase_date: "",
      delivery_date: "",
      serial_num: "",
      price: "",
      image: "",
    },
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      console.log(values);

      if (
        emailerror === "" &&
        promotionError === "" &&
        promotionCodeError === ""
      ) {
        var bodyFormData = new FormData();
        // FORM 1

        bodyFormData.append("city_id", values.city2);
        bodyFormData.append("state_id", values.stateprov2);
        bodyFormData.append("zip", values.zipcode);
        bodyFormData.append("email", values.email);
        bodyFormData.append("last_name", values.lname);
        bodyFormData.append("first_name", values.fname);
        bodyFormData.append("promotion_id", values.promotion_id);
        bodyFormData.append("promotion_code", values.promo_code);
        bodyFormData.append("retailer_name", values.retailer);

        bodyFormData.append("brand", values.brand);
        bodyFormData.append("model_number", values.model);
        bodyFormData.append("purchase_date", values.purchase_date);
        bodyFormData.append("delivery_date", values.delivery_date);
        bodyFormData.append("serial_number", values.serial_num);
        bodyFormData.append("price", values.price);
        bodyFormData.append("phone", values.phone);
        bodyFormData.append("address", values.address1);
        bodyFormData.append("file", image, image.name);
        claimNow(bodyFormData);
      }
    },
  });

  const checkPromotionId = async (id) => {
    if (id !== "") {
      setDisable(true);
      try {
        const response = await UserService.validatePromotionId(id);

        if (response.status === 200) {
          setDisable(false);

          setPromotionError("");
        }
      } catch (err) {
        if (err.response.status === 404) {
          setDisable(false);
          setPromotionError(err.response.data.response);
        } else {
          setDisable(false);
          setPromotionError("Error in checking Promotion id");

          Swal.fire({
            title: "Error!",
            text: "Some thing went wrong!",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setDisable(false);

      setPromotionError("");
    }
  };

  const checkPromotionCode = async (code) => {
    if (code !== "") {
      setDisable(true);
      try {
        const response = await UserService.valcodeatePromotionCode(code);

        if (response.status === 200) {
          setDisable(false);

          setPromotionCodeError("");
        }
      } catch (err) {
        if (err.response.status === 404) {
          setDisable(false);
          setPromotionCodeError(err.response.data.response);
        } else {
          setDisable(false);
          setPromotionError("Error in checking Promotion Code");
          Swal.fire({
            title: "Error!",
            text: "Some thing went wrong!",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setDisable(false);

      setPromotionCodeError("");
    }
  };

  const claimNow = async (values) => {
    setDisable(true);
    setLoading(true);
    try {
      const response = await UserService.SubmitClaim(values);

      if (response.status === 201) {
        setDisable(false);
        setLoading(false);

        toast.success("Your claim has beenm", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
        // action.resetForm();
      }
    } catch (err) {
      if (err.response.status === 401) {
        setDisable(false);
        setLoading(false);

        toast.error("Error, Try Again Later!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 403) {
        setDisable(false);
        setLoading(false);

        toast.error("Sorry, you are not allowed to submit claim", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 422) {
        setDisable(false);
        setLoading(false);

        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setDisable(false);
        setLoading(false);

        toast.error("Error, Some thing went wrong!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    getIp();
  }, [handleSubmit]);

  const onFileChange = (e) => {
    setImage(e);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ToastContainer />

      {/* <div className="user-panel"> */}
      <div className="pad-bot">
        <ToTop />
        <HeadRegister />
        <section className="slide-up registerSide">
          <div className="container">
            <div className="slides-here">
              <h2 className="slide-heading1">
                <span>REBATE</span> FORM
              </h2>
            </div>
          </div>
        </section>

        <section className="claims-part">
          <div className="container">
            <div className="row">
              <div className="Whitebg-box" id="RegisterBox">
                <form onSubmit={handleSubmit} noValidate>
                  <ul className="timeline claim_detail">
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <DangerInfo />
                            </div>
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                ENTER PROMOTION INFORMATION
                              </h4>
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.promotion_id}
                                  checkPromotionId={checkPromotionId}
                                  touched={touched.promotion_id}
                                  values={values.promotion_id}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="PROMOTION ID"
                                  spanText="PROMOTION ID"
                                  fieldName="promotion_id"
                                  fieldType="text"
                                  required={true}
                                />
                                {promotionError && !errors.promotion_id ? (
                                  <p
                                    className="help is-danger"
                                    style={{ marginTop: "-10px" }}
                                  >
                                    {promotionError}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.promo_code}
                                  checkPromotionCode={checkPromotionCode}
                                  touched={touched.promo_code}
                                  values={values.promo_code}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="PROMO CODE"
                                  spanText="PROMO CODE"
                                  fieldName="promo_code"
                                  fieldType="text"
                                  required={true}
                                />
                                {promotionCodeError && !errors.promo_code ? (
                                  <p
                                    className="help is-danger"
                                    style={{ marginTop: "-10px" }}
                                  >
                                    {promotionCodeError}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                ENTER PERSONAL INFORMATION
                              </h4>{" "}
                              <br />
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.fname}
                                  touched={touched.fname}
                                  values={values.fname}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="FIRST NAME"
                                  spanText="FIRST NAME"
                                  fieldName="fname"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.lname}
                                  touched={touched.lname}
                                  values={values.lname}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="LAST NAME"
                                  spanText="LAST NAME"
                                  fieldName="lname"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.address1}
                                  touched={touched.address1}
                                  values={values.address1}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="ADDRESS 1"
                                  spanText="ADDRESS 1"
                                  fieldName="address1"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>
                              <div className="col-lg-6">
                                <div
                                  className={`form-floating ${
                                    errors.stateprov2 && touched.stateprov2
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    className={
                                      errors.stateprov2 && touched.stateprov2
                                        ? "error-select-search"
                                        : ""
                                    }
                                    placeholder={"SELECT STATE/PROVINCE"}
                                    options={userState}
                                    isSearchable={true}
                                    name="stateprov2"
                                    onChange={(selectedOption) => {
                                      setFieldValue("city2", "");

                                      if (selectedOption) {
                                        setFieldValue(
                                          "stateprov2",
                                          selectedOption.value
                                        );
                                        changeUserCity(selectedOption.value);
                                      } else {
                                        setFieldValue("stateprov2", "");
                                      }
                                    }}
                                    onBlur={() =>
                                      setFieldTouched("stateprov2", true)
                                    }
                                  />
                                  <label className="with-select">
                                    <span>*</span> SELECT STATE/PROVINCE
                                  </label>
                                  {errors.stateprov2 && touched.stateprov2 ? (
                                    <p className="help is-danger">
                                      {errors.stateprov2}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                {userCityLoader ? (
                                  <h5 class="card-title placeholder-glow">
                                    <span
                                      class="placeholder col-12"
                                      style={{
                                        height: "58px",
                                        marginTop: "4px",
                                      }}
                                    ></span>
                                  </h5>
                                ) : (
                                  <div
                                    className={`form-floating ${
                                      errors.city2 && touched.city2
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className={
                                        errors.city2 && touched.city2
                                          ? "error-select-search"
                                          : ""
                                      }
                                      placeholder={"SELECT CITY"}
                                      options={userCity}
                                      isSearchable={true}
                                      name="city2"
                                      onChange={(selectedOption) => {
                                        if (selectedOption) {
                                          setFieldValue(
                                            "city2",
                                            selectedOption.value
                                          );
                                        } else {
                                          setFieldValue("city2", "");
                                        }
                                      }}
                                      onBlur={() =>
                                        setFieldTouched("city2", true)
                                      }
                                    />
                                    <label className="with-select">
                                      <span>*</span> CITY
                                    </label>
                                    {errors.city2 && touched.city2 ? (
                                      <p className="help is-danger">
                                        {errors.city2}
                                      </p>
                                    ) : null}
                                  </div>
                                )}
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.zipcode}
                                  touched={touched.zipcode}
                                  values={values.zipcode}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="ZIP CODE"
                                  spanText="ZIP CODE"
                                  fieldName="zipcode"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>
                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.email}
                                  touched={touched.email}
                                  values={values.email}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="EMAIL"
                                  spanText="EMAIL"
                                  fieldName="email"
                                  fieldType="email"
                                  required={true}
                                />
                                {emailerror ? (
                                  <p className="help is-danger">{emailerror}</p>
                                ) : null}
                              </div>
                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.phone}
                                  touched={touched.phone}
                                  values={values.phone}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  // onKeyUp={onlyNumbers}
                                  // onPaste={pasted}
                                  placeholder="PHONE NUMBER"
                                  spanText="PHONE NUMBER"
                                  fieldName="phone"
                                  fieldType="phone"
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                ENTER PRODUCT INFORMATION
                              </h4>
                            </div>
                          </div>
                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-12">
                                <InputField
                                  errors={errors.retailer}
                                  touched={touched.retailer}
                                  values={values.retailer}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="RETAILER NAME"
                                  spanText="RETAILER NAME"
                                  fieldName="retailer"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>
                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.brand}
                                  touched={touched.brand}
                                  values={values.brand}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="BRAND/MANUFACTURER"
                                  spanText="BRAND/MANUFACTURER"
                                  fieldName="brand"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>
                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.model}
                                  touched={touched.model}
                                  values={values.model}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="MODEL NUMBER"
                                  spanText="MODEL NUMBER"
                                  fieldName="model"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>

                              <div className="col-lg-6">
                                <div
                                  className={`form-floating ${
                                    errors.purchase_date &&
                                    touched.purchase_date
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <input
                                    id={inputTypes[1].id}
                                    type={inputTypes[1].type}
                                    onClick={() =>
                                      handleInputClick(inputTypes[0].id)
                                    }
                                    onFocus={() =>
                                      handleInputClick(inputTypes[1].id)
                                    }
                                    placeholder=" "
                                    className="form-control"
                                    onKeyUp={(e) => e.preventDefault()}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="purchase_date"
                                    value={values.purchase_date || ""}
                                    required
                                  />
                                  <label className="date">
                                    <span>*</span> PURCHASE DATE
                                  </label>
                                  {errors.purchase_date &&
                                  touched.purchase_date ? (
                                    <p className="help is-danger">
                                      {errors.purchase_date}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div
                                  className={`form-floating ${
                                    errors.delivery_date &&
                                    touched.delivery_date
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <input
                                    id={inputTypes[1].id}
                                    type={inputTypes[1].type}
                                    onClick={() =>
                                      handleInputClick(inputTypes[1].id)
                                    }
                                    onFocus={() =>
                                      handleInputClick(inputTypes[1].id)
                                    }
                                    placeholder=" "
                                    className="form-control"
                                    onKeyUp={(e) => e.preventDefault()}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="delivery_date"
                                    value={values.delivery_date || ""}
                                    required
                                  />
                                  <label className="date">
                                    <span>*</span> DELIVERY DATE
                                  </label>
                                  {errors.delivery_date &&
                                  touched.delivery_date ? (
                                    <p className="help is-danger">
                                      {errors.delivery_date}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.serial_num}
                                  touched={touched.serial_num}
                                  values={values.serial_num}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="SERIAL NUMBER"
                                  spanText="SERIAL NUMBER"
                                  fieldName="serial_num"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.price}
                                  touched={touched.price}
                                  values={values.price}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  // onKeyUp={onlyNumbers}
                                  // onPaste={pasted}
                                  placeholder="PRICE"
                                  spanText="PRICE"
                                  fieldName="price"
                                  fieldType="test"
                                  required={true}
                                />
                              </div>
                              <div className="col-lg-12">
                                <InputField
                                  className="file"
                                  errors={errors.image}
                                  touched={touched.image}
                                  values={values.image}
                                  handleChange={(e) => {
                                    handleChange(e);
                                    onFileChange(e.target.files[0]);
                                  }}
                                  handleBlur={handleBlur}
                                  placeholder="UPLOAD FILE"
                                  spanText="UPLOAD FILE"
                                  fieldName="image"
                                  fieldType="file"
                                  required={true}
                                />
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="reset"
                                  className="round-red-btn w-100 mt-3 reset-reg"
                                  value="RESET FORM"
                                  onClick={() => window.location.reload(false)}
                                />
                              </div>

                              <div className="col-lg-6">
                                <button
                                  type="submit"
                                  className="round-red-btn w-100 mt-3 "
                                  value="REGISTER"
                                  disabled={disable}
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  SUBMIT
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </section>

        <FootRegister />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}

export default Registration;
