import React from "react";
import "./PageNotFound.css";
import { Link } from "react-router-dom";
import ErrorIcon from "../assets/found_8846548.png";
export default function PageNotFound() {
  return (
    <>
      <div className="login-admin-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="text-center">
                <Link to="/">
                  <img src="/images/logo.png" className="logo" alt="" />
                </Link>
              </div>

              <div className="content-login">
                <div className="row">
                  <div className="adminForm nointernet">
                    <img src={ErrorIcon} className="errorIcon" />
                    <p className="prf-hed">404 - Page Not Found!</p>
                    <ul className="list-unstyled text-muted">
                      {" "}
                      <li>
                        The page you are looking for might have been removed{" "}
                        <br />
                        had its changed or is temporarily unavailable.
                      </li>
                    </ul>
                    <br />
                    <Link to="/" className="adminBtn">
                      Back to Home
                    </Link>
                  </div>
                </div>

                <br />
              </div>
            </div>

            <div className="col-12">
              <p className="copyright">
                © Copyrights {new Date().getFullYear()} all rights reserved by
                Select Your Rebate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
