import React from "react";

import { useMask } from "react-mask-field";

export default function InputField(props) {
  if (props.fieldName === "email") {
    return (
      <div
        className={`form-floating ${
          props.errors && props.touched ? "is-danger" : ""
        }`}
      >
        <input
          type={props.fieldType}
          className="form-control"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          placeholder={props.placeholder}
          name={props.fieldName}
          value={props.values || ""}
          disabled={props.disabled}
          required
        />
        <label>
          <span className={`${props.required ? "d-inline-block" : "d-none"}`}>
            *{" "}
          </span>{" "}
          {props.spanText}
        </label>

        {props.errors && props.touched ? (
          <p className="help is-danger">{props.errors}</p>
        ) : null}
      </div>
    );
  } else if (props.fieldName === "promotion_id") {
    return (
      <div
        className={`form-floating ${
          props.errors && props.touched ? "is-danger" : ""
        }`}
      >
        <input
          type={props.fieldType}
          className="form-control"
          onChange={props.handleChange}
          onBlur={(e) => {
            props.handleBlur(e);
            props.checkPromotionId(e.target.value);
          }}
          placeholder={props.placeholder}
          name={props.fieldName}
          value={props.values || ""}
          required={props.required}
          disabled={props.disabled}
        />

        <label>
          <span className={`${props.required ? "d-inline-block" : "d-none"}`}>
            *{" "}
          </span>{" "}
          {props.spanText}
        </label>

        {props.errors && props.touched ? (
          <p className="help is-danger">{props.errors}</p>
        ) : null}
      </div>
    );
  } else if (props.fieldName === "promo_code") {
    return (
      <div
        className={`form-floating ${
          props.errors && props.touched ? "is-danger" : ""
        }`}
      >
        <input
          type={props.fieldType}
          className="form-control"
          onChange={props.handleChange}
          onBlur={(e) => {
            props.handleBlur(e);
            props.checkPromotionCode(e.target.value);
          }}
          placeholder={props.placeholder}
          name={props.fieldName}
          value={props.values || ""}
          required={props.required}
          disabled={props.disabled}
        />

        <label>
          <span className={`${props.required ? "d-inline-block" : "d-none"}`}>
            *{" "}
          </span>{" "}
          {props.spanText}
        </label>

        {props.errors && props.touched ? (
          <p className="help is-danger">{props.errors}</p>
        ) : null}
      </div>
    );
  } else {
    return (
      <div
        className={`form-floating ${
          props.errors && props.touched ? "is-danger" : ""
        }`}
      >
        <input
          type={props.fieldType}
          className="form-control"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          placeholder={props.placeholder}
          name={props.fieldName}
          value={props.values || ""}
          disabled={props.disabled}
          required
        />
        <label>
          <span className={`${props.required ? "d-inline-block" : "d-none"}`}>
            *{" "}
          </span>{" "}
          {props.spanText}
        </label>

        {props.errors && props.touched ? (
          <p className="help is-danger">{props.errors}</p>
        ) : null}
      </div>
    );
  }
}
