import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./AdminForgotPass.css";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import "react-toastify/dist/ReactToastify.css";
import "../includes/general.css";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { forgotPassword } from "../../schema";
import UserService from "../../../services/user.service";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

export default function ForgotPass() {
  const TITLE = "Select Your Rebate | Forgot Your Password";
  const [loading, setLoading] = useState(false);

  const [alertdan, setalertdan] = useState(false);
  const [alertsuc, setalertsuc] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgotPassword,
      onSubmit: (values, action) => {
        forgot(action);
      },
    });
  const forgot = async (action) => {
    setLoading(true);
    try {
      const response = await UserService.AdminforgotPass(values);
      const { data } = response;
      const { response: res } = data;
      toast.success(res, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setLoading(false);
      setalertsuc(true);
      setalertdan(false);
      action.resetForm();
    } catch (err) {
      if (err.response.status === 404) {
        console.log(err);
        toast.success("You a re not are registered user!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
        setalertsuc(false);
        setalertdan(true);
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="login-admin-1">
        <ToastContainer />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="text-center">
                <Link to="/">
                  <img src="/images/logo.png" className="logo" alt="" />
                </Link>
              </div>
              <div className="content-login">
                {/* <h2 style={{ textAlign: "center" }}>Forgot Password</h2> */}
                <div className="row">
                  <form
                    action=""
                    onSubmit={handleSubmit}
                    noValidate
                    className="adminForm"
                  >
                    <p className="prf-hed">Forgot Your Password</p>
                    <p style={{ color: "#fff" }}>
                      Enter your email and to receive instructions
                      <hr className="underLine" />
                    </p>
                    <label style={{ marginBottom: "3px" }}>
                      RECOVERY EMAIL
                    </label>
                    <div className="left-inner-addon input-container">
                      <i class="bi bi-envelope"></i>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        value={values.email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="email"
                        className="userField"
                        required
                      />
                      {errors.email && touched.email ? (
                        <p className="help is-danger">{errors.email}</p>
                      ) : null}
                    </div>
                    <br />

                    <div className="col-lf">
                      <input className="adminBtn" type="submit" name="" />
                    </div>
                    <Link to="/admin" className="backBtn">
                      Back To Login
                    </Link>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12">
              <p className="copyright">
                © Copyrights {new Date().getFullYear()} all rights reserved by
                selectyourrebate.com
              </p>
            </div>
          </div>
        </div>
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
