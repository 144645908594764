import React from "react";
import "./Alerts.css";
import $ from "jquery";

export default function DangerInfo() {
  $(".close").click(function () {
    $(this).parent(".alert").fadeOut();
  });

  return (
    <div className="alert danger-alert">
      <h3 className="alertInfo-text">
        <i className="bi bi-info-circle-fill text-dark mr-3 danger"></i>
        &nbsp;Field(s) marked with Asterisk (
        <span style={{ color: "#B51019" }}>*</span>) are mandatory!
      </h3>
      <a className="close danger">&times;</a>
    </div>
  );
}
