import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import { useFormik } from "formik";
import { signImSchema } from "../../../schema";
import { toast, ToastContainer } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { Helmet } from "react-helmet";

export default function AdminLogin() {
  const TITLE = "Select Your Rebate | Admin Login";
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const login = async (values, action) => {
    setLoading(true);
    try {
      const response = await AdminListService.login(values);

      if (response.status === 200) {
        userActions.logIn({
          accessToken: response.data.access_token,
          id: response.data.user.id,
          uName: response.data.user.username,
          uType: response.data.user.user_type,
          name: response.data.user.fname + " " + response.data.user.lname,
        });
        action.resetForm();
        navigate("/admin/dashboard");
        setLoading(false);

        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        toast.error("Username or Password is invalid !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);
        console.log(err);
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        password: "",
      },
      validationSchema: signImSchema,
      onSubmit: (values, action) => {
        login(values, action);
      },
    });

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="login-admin-1">
        <ToastContainer />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="text-center">
                <Link to="/">
                  <img src="/images/logo.png" className="logo" alt="" />
                </Link>
              </div>

              <div className="content-login">
                <div className="row">
                  <form
                    action=""
                    onSubmit={handleSubmit}
                    noValidate
                    className="adminForm"
                  >
                    <p className="prf-hed">Admin Login</p>
                    <div className="left-inner-addon input-container">
                      <i class="bi bi-person-fill"></i>
                      <input
                        type="text"
                        placeholder="Username"
                        value={values.name || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="name"
                        className="userField"
                        required
                      />
                      {errors.name && touched.name ? (
                        <p className="help is-danger">{errors.name}</p>
                      ) : null}
                    </div>

                    <div className="left-inner-addon input-container">
                      <i class="bi bi-lock-fill"></i>
                      <input
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="password"
                        value={values.password || ""}
                        className="passField"
                      />
                      {errors.password && touched.password ? (
                        <p className="help is-danger">{errors.password}</p>
                      ) : null}
                    </div>
                    <br />
                    <input type="submit" value="Sign in" className="adminBtn" />
                    <br />
                    <Link to="/admin/forgot-pass" className="forgot">
                      Forgot Password?
                    </Link>

                    {/* <div className="drops">
                      <div className="drop drop-1"></div>
                      <div className="drop drop-2"></div>
                      <div className="drop drop-3"></div>
                      <div className="drop drop-4"></div>
                      <div className="drop drop-5"></div>
                    </div> */}
                  </form>
                </div>

                <br />
              </div>
            </div>

            <div className="col-12">
              <p className="copyright">
                © Copyrights {new Date().getFullYear()} all rights reserved by
                Select Your Rebate.
              </p>
            </div>
          </div>
        </div>
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
