import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router";
import "./App.css";
import AdminLogin from "./Components/Admin/Login/AdminLogin";
import Dashboard from "./Components/Dashboard/Dashboard";
import AllClaims from "./Components/Admin/Claims/AllClaims";
import AcceptedClaims from "./Components/Admin/Claims/AcceptedClaims";
import RejectedClaims from "./Components/Admin/Claims/RejectedClaims";
import ArchivedClaim from "./Components/Admin/Claims/Archived_Claim/ArchivedClaim";
import ProfileAdmin from "./Components/Admin/ProfileAdmin/ProfileAdmin";
import ReportsHistory from "./Components/Admin/Reports/ReportsHistory";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "./Redux";
import Registration from "./Components/User/Registration/Registration";
import ViewDetail from "./Components/Dashboard/ViewDetail";
import AdminForgotPass from "./Components/Admin/AdminForgotPass/AdminForgotPass";
import CheckAdminForgetToken from "./Components/Admin/AdminForgotPass/CheckAdminForgetToken";
import AdminChangePass from "./Components/Admin/AdminForgotPass/AdminChangePass";
import ArchivedDosClaim from "./Components/Admin/Claims/Archived_Claim/ArchivedDosClaim";
import ViewStaticsClaims from "./Components/Admin/AppStatistics/ViewStaticsClaims";
import NoInternet from "./Components/NoInternet/NoInternet";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import { useEffect } from "react";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

function App() {
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();
  const locat = useLocation();
  const state = useSelector((state) => state.stateVals);
  // const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { accessToken } = state;

  let decoded;

  useEffect(() => {
    // auto logout code start

    if (locat.pathname !== "/") {
      const callFun = window["onUrlChange"];
      if (accessToken) {
        decoded = jwt_decode(accessToken);
        // auto logout code start
        if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
          userActions.logOut(null);
          Swal.fire({
            title: "Expired!",
            text: "Your Login Session expired!",
            icon: "error",
            confirmButtonText: "Login Again",
          });
          navigate("/admin");
        } else if (
          locat.pathname === "/admin" ||
          locat.pathname === "/admin/" ||
          locat.pathname === "/admin/forgot-pass" ||
          locat.pathname === "/admin/forgot-pass/"
        ) {
          callFun();
          navigate("/admin/dashboard");
        }
      } else {
        if (
          locat.pathname !== "/admin/forgot-pass" &&
          locat.pathname !== "/admin/forgot-token" &&
          locat.pathname !== "/admin/change-pass" &&
          locat.pathname !== "/admin/forgot-pass/" &&
          locat.pathname !== "/admin/change-pass/" &&
          locat.pathname.indexOf("admin") > -1
        ) {
          navigate("/admin");
        }
      }
    }
  }, [locat.pathname]);

  return (
    <NoInternet>
      <Routes>
        <Route path="/" element={<Registration />} />
        {/* admin routing here  */}
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/forgot-pass" element={<AdminForgotPass />} />
        <Route path="/admin/forgot-token" element={<CheckAdminForgetToken />} />
        <Route path="/admin/change-pass" element={<AdminChangePass />} />
        <Route path="/admin/profile-admin" element={<ProfileAdmin />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route
          path="/admin/dashboard/view-detail/:id"
          element={<ViewDetail />}
        />
        <Route path="/admin/claims" element={<AllClaims />} />
        <Route path="/admin/accepted-claim" element={<AcceptedClaims />} />
        <Route path="/admin/rejected-claim" element={<RejectedClaims />} />
        <Route path="/admin/archived-claim" element={<ArchivedClaim />} />
        <Route
          path="/admin/archived-dos-claim"
          element={<ArchivedDosClaim />}
        />

        <Route path="/admin/reports-history" element={<ReportsHistory />} />

        {/* 4 pages for this endpoint */}

        <Route
          path="/admin/view-statics-claims/:userId"
          element={<ViewStaticsClaims />}
        />

        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </NoInternet>
  );
}

export default App;
