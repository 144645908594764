import React, { useState, useEffect } from "react";
import "./NoInternet.css";
import { Link } from "react-router-dom";

const NoInternet = (props) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <>
        <div className="login-admin-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-5">
                <div className="text-center">
                  <Link to="/">
                    <img src="/images/logo.png" className="logo" alt="" />
                  </Link>
                </div>

                <div className="content-login">
                  <div className="row">
                    <div className="adminForm nointernet">
                      <i class="bi bi-wifi-off prf-hed"></i>
                      <p className="prf-hed">No Internet Connection!</p>
                      <ul className="list-unstyled text-muted">
                        {" "}
                        <li id="des">
                          Please re-connect to the internet to continue use
                          <span id="siteName"> selectyourrebate.com</span>
                        </li>{" "}
                        <br />
                        <li>If you encounter problems:</li>
                        <ul>
                          <li className="mt-2 inner">
                            Try restarting wireless connection on this device.
                          </li>
                          <li className="mt-2 inner">Move close to your wireless access point.</li>
                        </ul>
                      </ul>
                    </div>
                  </div>

                  <br />
                </div>
              </div>

              <div className="col-12">
                <p className="copyright">
                  © Copyrights {new Date().getFullYear()} all rights reserved by
                  Select Your Rebate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default NoInternet;
