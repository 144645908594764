import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import RebateLogo from "../../assets/logo-3.png";
import "./PreLoader.css";
export default function PreLoader() {
  const location = useLocation();

  const [pivi, setPvisi] = useState();

  useEffect(() => {
    console.log("false");
    // setPvisi(false);

    setTimeout(() => {
      console.log("true");
      //   setPvisi(true);
    }, 1000);
  }, []);

  return (
    <>
      {/* <div className={`preloader ${pivi ? "fadeOut" : "fadeIn"}`}>
        <div className="vertical-centered-box">
          <div className="content">
            <div className="loader-circle"></div>
            <div className="loader-line-mask">
              <div className="loader-line"></div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className={`loader ${pivi ? "in" : ""}`}>
        <div className="spinner-border main-spin">
          <img src={RebateLogo} alt="logo" />
        </div>
      </div> */}
    </>
  );
}
