import React from "react";
import { Link } from "react-router-dom";

import HeaderProfile from "./HeaderProfile";

export default function HeaderSidebar() {
  return (
    <>
      <HeaderProfile />
      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          {/* <h4>Select Your Rebate</h4> */}
          <Link to="/admin/dashboard">
            <img
              src="/admin_assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </Link>
          <div>
            <img
              src="/admin_assets/images/logo-icon-2.png"
              className="logo-icon-2"
              alt="logo icon"
            />
          </div>
          <div className="toggle-icon ms-auto">
            {" "}
            <i className="bi bi-list"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu">
          <li>
            <Link to="/admin/dashboard">
              <div className="parent-icon">
                <i className="fadeIn animated bx bx-home-circle"></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </Link>
          </li>

          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="bi bi-grid-fill"></i>
              </div>
              <div className="menu-title">Claims</div>
            </a>
            <ul>
              <li>
                <Link to="/admin/claims">
                  <i className="bi bi-circle"></i>Latest Claims
                </Link>
              </li>
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <i className="bi bi-circle"></i>Processed Claims
                </a>
                <ul>
                  <li>
                    <Link to="/admin/accepted-claim">
                      <i className="bi bi-circle"></i>Accepted Claims
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/rejected-claim">
                      <i className="bi bi-circle"></i>Rejected Claims
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="fadeIn animated bx bx-archive-in"></i>
              </div>
              <div className="menu-title">Archive Claims</div>
            </a>
            <ul>
              <li>
                <Link to="/admin/archived-claim">
                  <i className="bi bi-circle"></i>All Archive Claims
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="lni lni-library "></i>
              </div>
              <div className="menu-title">REPORTS</div>
            </a>
            <ul>
              <li>
                <Link to="/admin/reports-history">
                  <i className="bi bi-circle"></i>Reports History{" "}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    </>
  );
}
